import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb496f16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: { display: 'flex' } }
const _hoisted_2 = { class: "el-dialog__title" }
const _hoisted_3 = { style: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingRight: '1rem',
          } }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_tree = _resolveComponent("el-tree")!
  const _component_analytics = _resolveComponent("analytics")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.showSettings,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showSettings) = $event)),
    "before-close": _ctx.handleClose,
    width: "calc(var(--app-width) * 0.8)"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('moderator.organism.settings.analytics.header')), 1)
      ])
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_component_el_tree, {
        class: "tree",
        ref: "tree",
        key: _ctx.treeDataKey,
        style: {"max-width":"600px"},
        data: _ctx.treeData,
        "node-key": "id",
        "default-checked-keys": _ctx.selectedKeys,
        props: {
        children: 'tasks',
        label: (data) => data.name || data.title,
        //disabled: (data) => data.participantCount === 0,
        disabled: false,
      },
        "show-checkbox": "",
        onCheckChange: _ctx.onCheckChange
      }, {
        default: _withCtx(({ data }) => [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("span", null, [
              (data.taskType)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    icon: _ctx.getIconOfType(data.taskType.toLowerCase()),
                    style: _normalizeStyle({
                color: _ctx.getColorOfType(data.taskType.toLowerCase()),
              })
                  }, null, 8, ["icon", "style"]))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(data.name || data.title), 1)
            ]),
            (data.participantCount >= 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _createTextVNode(_toDisplayString(data.participantCount) + " ", 1),
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'user'] })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["data", "default-checked-keys", "props", "onCheckChange"])),
      _createVNode(_component_analytics, {
        "session-id": _ctx.sessionId,
        "received-tasks": _ctx.selectedTasks
      }, null, 8, ["session-id", "received-tasks"])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}