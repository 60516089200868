import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b470840"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["element-loading-background", "element-loading-text"]
const _hoisted_2 = ["element-loading-background", "element-loading-text"]
const _hoisted_3 = ["element-loading-background", "element-loading-text"]
const _hoisted_4 = ["element-loading-background", "element-loading-text"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_parallel_coordinates = _resolveComponent("parallel-coordinates")!
  const _component_Tables = _resolveComponent("Tables")!
  const _component_radar_chart = _resolveComponent("radar-chart")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    id: "analytics",
    style: { marginTop: '3rem' },
    "element-loading-background": 'var(--color-background)',
    "element-loading-text": _ctx.$t('moderator.organism.analytics.loadingNoTasks')
  }, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "AnalyticsParallelCoordinates",
      "element-loading-background": 'var(--color-background)',
      "element-loading-text": _ctx.$t('moderator.organism.analytics.loading')
    }, [
      (_ctx.axes.length > 0 && _ctx.dataEntries.length > 0 && !_ctx.loadingSteps)
        ? (_openBlock(), _createBlock(_component_parallel_coordinates, {
            key: 0,
            "chart-axes": 
          JSON.parse(JSON.stringify(_ctx.axes.filter((axis) => axis.available)))
        ,
            "participant-data": JSON.parse(JSON.stringify(_ctx.dataEntries)),
            steps: JSON.parse(JSON.stringify(_ctx.steps)),
            selectedParticipantIds: _ctx.selectedParticipantIds,
            "onUpdate:selectedParticipantIds": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedParticipantIds) = $event)),
            onParticipantSelected: _ctx.participantSelectionChanged,
            style: _normalizeStyle({
          opacity: _ctx.loadingSteps ? 0 : 1,
        })
          }, null, 8, ["chart-axes", "participant-data", "steps", "selectedParticipantIds", "onParticipantSelected", "style"]))
        : _createCommentVNode("", true)
    ], 8, _hoisted_2)), [
      [_directive_loading, _ctx.loadingSteps]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "AnalyticsTables",
      "element-loading-background": 'var(--color-background)',
      "element-loading-text": _ctx.$t('moderator.organism.analytics.loading')
    }, [
      (_ctx.axes.length > 0 && _ctx.dataEntries.length > 0 && !_ctx.loadingSteps)
        ? (_openBlock(), _createBlock(_component_Tables, {
            key: 0,
            "participant-data": JSON.parse(JSON.stringify(_ctx.dataEntries)),
            axes: 
          JSON.parse(JSON.stringify(_ctx.axes.filter((axis) => axis.available)))
        ,
            selectedParticipantIds: _ctx.selectedParticipantIds,
            "onUpdate:selectedParticipantIds": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedParticipantIds) = $event)),
            style: _normalizeStyle({
          opacity: _ctx.loadingSteps ? 0 : 1,
        })
          }, null, 8, ["participant-data", "axes", "selectedParticipantIds", "style"]))
        : _createCommentVNode("", true)
    ], 8, _hoisted_3)), [
      [_directive_loading, _ctx.loadingSteps]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "RadarChartContainer",
      "element-loading-background": 'var(--color-background)',
      "element-loading-text": _ctx.$t('moderator.organism.analytics.loading')
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radarDataEntries, (entry, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'radarChart' + index,
          style: _normalizeStyle({
          opacity: _ctx.loadingSteps ? 0 : 1,
        })
        }, [
          _createVNode(_component_radar_chart, {
            labels: entry.labels,
            datasets: entry.data,
            test: entry.test,
            title: entry.title,
            size: 300,
            levels: 5,
            defaultColor: 'var(--color-dark-contrast-light)',
            selectedParticipantIds: _ctx.selectedParticipantIds,
            "onUpdate:selectedParticipantIds": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedParticipantIds) = $event)),
            onParticipantSelected: _ctx.participantSelectionChanged
          }, null, 8, ["labels", "datasets", "test", "title", "defaultColor", "selectedParticipantIds", "onParticipantSelected"])
        ], 4))
      }), 128))
    ], 8, _hoisted_4)), [
      [_directive_loading, _ctx.loadingSteps]
    ])
  ], 8, _hoisted_1)), [
    [_directive_loading, _ctx.receivedTasks.length <= 0]
  ])
}