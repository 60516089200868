import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c48e7a04"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "parentElement",
  class: "parallelCoordinatesContainer",
  style: {"position":"relative"}
}
const _hoisted_2 = { class: "participationContainer" }
const _hoisted_3 = { class: "listButton" }
const _hoisted_4 = { class: "el-dropdown-link" }
const _hoisted_5 = {
  key: 0,
  class: "dropDownParticipantIconContainer"
}
const _hoisted_6 = { class: "dropDownIdeaContainer" }
const _hoisted_7 = { class: "participantCount" }
const _hoisted_8 = ["fill"]
const _hoisted_9 = ["d"]
const _hoisted_10 = ["transform"]
const _hoisted_11 = ["transform", "d"]
const _hoisted_12 = ["transform"]
const _hoisted_13 = ["transform"]
const _hoisted_14 = ["transform", "d"]
const _hoisted_15 = ["transform"]
const _hoisted_16 = ["onMouseenter", "onMouseleave", "onClick"]
const _hoisted_17 = ["d"]
const _hoisted_18 = ["transform", "d", "fill"]
const _hoisted_19 = ["cx", "cy"]
const _hoisted_20 = ["d", "stroke-dasharray"]
const _hoisted_21 = ["transform"]
const _hoisted_22 = ["y1", "y2"]
const _hoisted_23 = ["y1", "y2"]
const _hoisted_24 = ["y"]
const _hoisted_25 = { class: "controls" }
const _hoisted_26 = { class: "axisControlsContainer" }
const _hoisted_27 = ["onDragstart", "onDrop"]
const _hoisted_28 = { class: "axisSelections" }
const _hoisted_29 = { class: "column" }
const _hoisted_30 = { class: "el-dropdown-link cogButton" }
const _hoisted_31 = { class: "column columnCenter" }
const _hoisted_32 = { class: "el-dropdown-link" }
const _hoisted_33 = { class: "centered-dropdown-item" }
const _hoisted_34 = { class: "column columnHover" }
const _hoisted_35 = { class: "heading twoLineText" }
const _hoisted_36 = { class: "subAxisName twoLineText" }
const _hoisted_37 = { class: "subAxisUnit twoLineText" }
const _hoisted_38 = { class: "el-dropdown-link" }
const _hoisted_39 = { class: "legend" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_IdeaCard = _resolveComponent("IdeaCard")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_VariableSVGWrapper = _resolveComponent("VariableSVGWrapper")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_ToolTip = _resolveComponent("ToolTip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeAxes, (axis) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "axisControls axisControlsFlex",
          key: axis.taskId,
          style: _normalizeStyle({
          textAlign: 'center',
          width: `${_ctx.axesSpacing}px`,
          gap: `${_ctx.axesSpacing / 4}px`,
          paddingLeft: `${_ctx.axesSpacing / 3.5}px`,
        })
        }, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.isIdeaTask(axis))
              ? (_openBlock(), _createBlock(_component_el_dropdown, {
                  key: 0,
                  trigger: "click",
                  placement: "bottom",
                  "max-height": _ctx.height,
                  "hide-on-click": false
                }, {
                  dropdown: _withCtx(() => [
                    _createVNode(_component_el_dropdown_menu, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCachedIdeas(axis), (entry) => {
                          return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                            key: entry.avatar.id + axis.taskId,
                            divided: true,
                            disabled: true,
                            style: _normalizeStyle({
                    cursor: 'default',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    pointerEvents: 'none',
                    backgroundColor: _ctx.selectedParticipantIds.includes(
                      entry.avatar.id
                    )
                      ? 'var(--color-background-blue)'
                      : 'transparent',
                  })
                          }, {
                            default: _withCtx(() => [
                              (entry.avatar)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _createVNode(_component_font_awesome_icon, {
                                      icon: entry.avatar.symbol,
                                      style: _normalizeStyle({ color: entry.avatar.color })
                                    }, null, 8, ["icon", "style"])
                                  ]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", _hoisted_6, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entry.ideas, (idea, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "ideaCardContainer",
                                    key: 
                        entry.avatar.id + axis.taskId + (idea ? idea.id : index)
                      
                                  }, [
                                    idea
                                      ? (_openBlock(), _createBlock(_component_IdeaCard, {
                                          key: 0,
                                          class: "IdeaCard",
                                          idea: idea,
                                          "is-selectable": false,
                                          "is-editable": false,
                                          cutLongTexts: true,
                                          portrait: !(idea.link || idea.image)
                                        }, null, 8, ["idea", "portrait"]))
                                      : _createCommentVNode("", true)
                                  ]))
                                }), 128))
                              ])
                            ]),
                            _: 2
                          }, 1032, ["style"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "list",
                        class: "listIcon"
                      })
                    ])
                  ]),
                  _: 2
                }, 1032, ["max-height"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("p", _hoisted_7, [
            _createVNode(_component_font_awesome_icon, { icon: "user" }),
            _createTextVNode(" " + _toDisplayString(_ctx.getParticipationCount(axis)), 1)
          ])
        ], 4))
      }), 128))
    ]),
    (_openBlock(), _createElementBlock("svg", {
      ref: "svgElement",
      class: "parallelSVG",
      style: _normalizeStyle({ paddingLeft: `${_ctx.paddingLeft}px` })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.averageDataLinePath, (pathPart, index) => {
        return (_openBlock(), _createElementBlock("g", {
          key: pathPart?.path,
          fill: 'var(--color-evaluating)'
        }, [
          pathPart
            ? (_openBlock(), _createElementBlock("path", {
                key: 0,
                class: "dataLine averageDataLine",
                d: pathPart.path,
                fill: "none"
              }, null, 8, _hoisted_9))
            : _createCommentVNode("", true),
          (_ctx.getAboveBelow(pathPart?.value, index, true) > 0)
            ? (_openBlock(), _createElementBlock("g", {
                key: 1,
                class: "aboveBelow",
                transform: `translate(${pathPart?.x + 7}, ${pathPart?.y + 5})`
              }, [
                _createElementVNode("path", {
                  transform: `translate(0, 0) scale(0.025)`,
                  d: _ctx.getIconDefinition('angle-down').icon[4] as string
                }, null, 8, _hoisted_11),
                _createElementVNode("text", {
                  "font-size": "12",
                  transform: `translate(10, 18)`
                }, _toDisplayString(_ctx.getAboveBelow(pathPart.value, index, true)), 9, _hoisted_12)
              ], 8, _hoisted_10))
            : _createCommentVNode("", true),
          (_ctx.getAboveBelow(pathPart?.value, index) > 0)
            ? (_openBlock(), _createElementBlock("g", {
                key: 2,
                class: "aboveBelow",
                transform: `translate(${pathPart?.x + 7}, ${pathPart?.y - 20})`
              }, [
                _createElementVNode("path", {
                  transform: `scale(0.025)`,
                  d: _ctx.getIconDefinition('angle-up').icon[4] as string
                }, null, 8, _hoisted_14),
                _createElementVNode("text", {
                  "font-size": "12",
                  transform: `translate(10, 0)`
                }, _toDisplayString(_ctx.getAboveBelow(pathPart.value, index)), 9, _hoisted_15)
              ], 8, _hoisted_13))
            : _createCommentVNode("", true)
        ], 8, _hoisted_8))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData, (entry) => {
        return (_openBlock(), _createElementBlock("g", {
          key: entry.participant.id
        }, [
          _createVNode(_component_VariableSVGWrapper, null, {
            default: _withCtx(({
              dataLine = this.getDataLine(entry),
              participantColor = entry.participant.avatar.color,
            }) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataLine, (pathPart) => {
                return (_openBlock(), _createElementBlock("g", {
                  key: pathPart?.path,
                  class: "dataLineHover",
                  fill: "none",
                  onMouseenter: ($event: any) => (
                _ctx.setHoverStroke(
                  participantColor,
                  entry.participant.id,
                  participantColor
                )
              ),
                  onMouseleave: ($event: any) => (
                _ctx.setHoverStroke(
                  'var(--color-dark-contrast)',
                  entry.participant.id,
                  participantColor
                )
              ),
                  onClick: ($event: any) => (_ctx.participantSelectionChanged(entry.participant.id))
                }, [
                  pathPart
                    ? (_openBlock(), _createElementBlock("path", {
                        key: 0,
                        d: pathPart.path
                      }, null, 8, _hoisted_17))
                    : _createCommentVNode("", true)
                ], 40, _hoisted_16))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataLine, (pathPart, index) => {
                return (_openBlock(), _createElementBlock("g", {
                  key: pathPart?.path,
                  class: _normalizeClass(["dataLine", entry.participant.id]),
                  fill: "none",
                  style: _normalizeStyle({
                strokeWidth: _ctx.selectedParticipantIds.includes(
                  entry.participant.id
                )
                  ? '3px'
                  : '',
                stroke: _ctx.selectedParticipantIds.includes(entry.participant.id)
                  ? participantColor
                  : 'var(--color-dark-contrast)',
              })
                }, [
                  (index === dataLine.length - 1)
                    ? (_openBlock(), _createElementBlock("path", {
                        key: 0,
                        class: "participantDataLineIcon",
                        transform: `translate(${pathPart?.x + 5}, ${
                  pathPart?.y - 10
                }), scale(0.04)`,
                        d: _ctx.getIconDefinition(entry.participant.avatar.symbol).icon[4] as string,
                        fill: entry.participant.avatar.color,
                        style: _normalizeStyle({
                  opacity: _ctx.selectedParticipantIds.includes(entry.participant.id)
                    ? 1
                    : 0,
                })
                      }, null, 12, _hoisted_18))
                    : _createCommentVNode("", true),
                  _createElementVNode("circle", {
                    class: "circle",
                    cx: pathPart?.x,
                    cy: pathPart?.y,
                    r: 0
                  }, null, 8, _hoisted_19),
                  pathPart
                    ? (_openBlock(), _createElementBlock("path", {
                        key: 1,
                        class: _normalizeClass(["dataLinePathSegment", { dashed: pathPart.dashed }]),
                        d: pathPart.path,
                        "stroke-dasharray": pathPart.dashed ? '4,4' : '0',
                        style: _normalizeStyle({
                  opacity: pathPart?.dashed
                    ? '20%'
                    : _ctx.selectedParticipantIds.includes(entry.participant.id)
                    ? '1'
                    : '45%',
                })
                      }, null, 14, _hoisted_20))
                    : _createCommentVNode("", true)
                ], 6))
              }), 128))
            ]),
            _: 2
          }, 1024)
        ]))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeAxes, (axis, index) => {
        return (_openBlock(), _createElementBlock("g", {
          class: "axes",
          key: axis.taskId,
          transform: `translate(${_ctx.axesSpacing * index + _ctx.paddingLeft}, 0)`
        }, [
          _createElementVNode("line", {
            y1: _ctx.padding,
            y2: _ctx.height - _ctx.padding,
            "stroke-width": "2",
            "stroke-linecap": "round"
          }, null, 8, _hoisted_22),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.getLabelCount(
            axis.axisValues.find((ax) => ax?.id === axis.categoryActive)?.range
          ) + 1, (labelIndex) => {
            return (_openBlock(), _createElementBlock("g", {
              key: labelIndex - 1 + axis.taskId
            }, [
              _createVNode(_component_VariableSVGWrapper, null, {
                default: _withCtx(({
                labelPosition = this.getLabelPosition(
                  labelIndex - 1,
                  this.getLabelCount(
                    axis.axisValues.find((ax) => ax?.id === axis.categoryActive)
                      ?.range
                  )
                ),
              }) => [
                  _createElementVNode("line", {
                    y1: labelPosition,
                    y2: labelPosition,
                    x1: -5,
                    x2: 5,
                    "stroke-width": "2",
                    "stroke-linecap": "round"
                  }, null, 8, _hoisted_23),
                  _createElementVNode("text", {
                    y: labelPosition - 6,
                    x: "0",
                    "font-size": "10.5",
                    class: "axisLabel",
                    "text-anchor": "middle",
                    style: _normalizeStyle({
                  stroke: 'var(--color-background)',
                  strokeWidth: '5pt',
                  paintOrder: 'stroke',
                  textAlign: 'center',
                })
                  }, _toDisplayString(Math.round(
                    ((axis.axisValues.find(
                      (value) => value.id === axis.categoryActive
                    ).range /
                      this.getLabelCount(
                        axis.axisValues.find(
                          (ax) => ax?.id === axis.categoryActive
                        )?.range
                      )) *
                      (labelIndex - 1) +
                      Number.EPSILON) *
                      100
                  ) / 100), 13, _hoisted_24)
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ], 8, _hoisted_21))
      }), 128))
    ], 4)),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("div", _hoisted_26, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeAxes, (axis, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "axisControls",
            key: axis.taskId,
            style: _normalizeStyle({
            textAlign: 'center',
            width: `${_ctx.axesSpacing / 1.5}px`,
            margin: `0 ${_ctx.axesSpacing / 6}px`,
          }),
            draggable: "true",
            onDragstart: ($event: any) => (_ctx.onDragStart(index)),
            onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
            onDrop: ($event: any) => (_ctx.onDrop(index))
          }, [
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                (axis.axisValues.length > 1)
                  ? (_openBlock(), _createBlock(_component_el_dropdown, {
                      key: 0,
                      onCommand: ($event: any) => (_ctx.updateSubAxis(index, $event)),
                      trigger: "click",
                      placement: "bottom"
                    }, {
                      dropdown: _withCtx(() => [
                        _createVNode(_component_el_dropdown_menu, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(axis.axisValues, (subAxis, subAxisIndex) => {
                              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: subAxis ? subAxis.id : subAxisIndex,
                                command: subAxis ? subAxis.id : null
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(subAxis
                          ? _ctx.$t(_ctx.getTranslationPath(axis) + subAxis.id)
                          : 'N/A'), 1)
                                ]),
                                _: 2
                              }, 1032, ["command"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_30, [
                          _createVNode(_component_font_awesome_icon, { icon: "cog" })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["onCommand"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_31, [
                (_ctx.availableAxes.length > 1)
                  ? (_openBlock(), _createBlock(_component_el_dropdown, {
                      key: 0,
                      onCommand: ($event: any) => (_ctx.activateAxis($event, index, true)),
                      trigger: "click",
                      placement: "bottom",
                      disabled: 
                  _ctx.availableAxes.filter((avAxis) => !this.axes.includes(avAxis))
                    .length < 1
                
                    }, {
                      dropdown: _withCtx(() => [
                        _createVNode(_component_el_dropdown_menu, { class: "centered-dropdown-menu" }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableAxes.filter(
                        (avAxis) =>
                          !this.axes.includes(avAxis) || !avAxis.active
                      ), (ax, axIndex) => {
                              return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                key: ax ? ax.taskId + 'ax' : axIndex + 'ax',
                                command: ax ? ax : null
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_33, [
                                    _createVNode(_component_font_awesome_icon, {
                                      class: "axisIcon",
                                      icon: _ctx.getIconOfAxis(ax),
                                      style: _normalizeStyle({
                            color: _ctx.getColorOfAxis(ax),
                          })
                                    }, null, 8, ["icon", "style"]),
                                    _createTextVNode("  " + _toDisplayString(ax ? ax.taskData.taskName : 'N/A'), 1)
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["command"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_32, [
                          _createVNode(_component_font_awesome_icon, {
                            class: "axisIcon",
                            icon: _ctx.getIconOfAxis(axis),
                            style: _normalizeStyle({
                      color: _ctx.getColorOfAxis(axis),
                    })
                          }, null, 8, ["icon", "style"])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["onCommand", "disabled"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_34, [
                _createVNode(_component_el_button, {
                  onClick: ($event: any) => (_ctx.deactivateAxis(axis)),
                  class: "trashButton"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_font_awesome_icon, { icon: ['fas', 'trash'] })
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ])
            ]),
            _createElementVNode("p", _hoisted_35, _toDisplayString(axis.taskData.taskName), 1),
            _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.$t(_ctx.getTranslationPath(axis) + axis.categoryActive)), 1),
            _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.$t(
                _ctx.getTranslationPath(axis) + 'units.' + axis.categoryActive
              ).slice(-axis.categoryActive.length) !== axis.categoryActive
                ? _ctx.$t(_ctx.getTranslationPath(axis) + 'units.' + axis.categoryActive)
                : ''), 1)
          ], 44, _hoisted_27))
        }), 128))
      ]),
      _createElementVNode("div", {
        class: "axisControlsContainer axisPlusControlsContainer",
        style: _normalizeStyle({ paddingLeft: `${_ctx.axesSpacing / 2}px` })
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeAxes.length - 1, (index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "axisPlusContainer",
            key: index - 1 + 'plus',
            style: _normalizeStyle({
            width: `${_ctx.axesSpacing / 3}px`,
            margin: `0 ${_ctx.axesSpacing / 3}px`,
          })
          }, [
            (
              _ctx.availableAxes.length > 1 &&
              _ctx.availableAxes.filter(
                (avAxis) => !this.axes.includes(avAxis) || !avAxis.active
              ).length >= 1
            )
              ? (_openBlock(), _createBlock(_component_el_dropdown, {
                  key: 0,
                  class: "axisPlus",
                  onCommand: ($event: any) => (_ctx.activateAxis($event, index)),
                  trigger: "click",
                  placement: "bottom"
                }, {
                  dropdown: _withCtx(() => [
                    _createVNode(_component_el_dropdown_menu, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableAxes.filter(
                    (avAxis) => !this.axes.includes(avAxis) || !avAxis.active
                  ), (ax, axIndex) => {
                          return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                            key: ax ? ax.taskId + 'ax' : axIndex + 'ax',
                            command: ax ? ax : null
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_font_awesome_icon, {
                                class: "axisIcon",
                                icon: _ctx.getIconOfAxis(ax),
                                style: _normalizeStyle({
                      color: _ctx.getColorOfAxis(ax),
                    })
                              }, null, 8, ["icon", "style"]),
                              _createTextVNode("  " + _toDisplayString(ax ? ax.taskData.taskName : 'N/A'), 1)
                            ]),
                            _: 2
                          }, 1032, ["command"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_38, [
                      _createVNode(_component_font_awesome_icon, { icon: ['fas', 'circle-plus'] })
                    ])
                  ]),
                  _: 2
                }, 1032, ["onCommand"]))
              : _createCommentVNode("", true)
          ], 4))
        }), 128))
      ], 4)
    ]),
    _createElementVNode("div", _hoisted_39, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legend, (legendItem) => {
        return (_openBlock(), _createBlock(_component_ToolTip, {
          class: "legendItem",
          key: legendItem.path,
          content: _ctx.$t(`moderator.organism.analytics.legend.${legendItem.name}`),
          "show-after": 200
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_image, {
              src: legendItem.path,
              alt: _ctx.$t(`moderator.organism.analytics.legend.${legendItem.name}`),
              fit: 'contain'
            }, null, 8, ["src", "alt"])
          ]),
          _: 2
        }, 1032, ["content"]))
      }), 128))
    ])
  ], 512))
}