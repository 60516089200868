import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    "show-after": _ctx.showAfter,
    placement: _ctx.placement,
    content: _ctx.text,
    effect: _ctx.effect,
    enterable: false,
    disabled: _ctx.disabled,
    trigger: _ctx.trigger
  }, {
    content: _withCtx(() => [
      (_ctx.text.length > 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "content")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["show-after", "placement", "content", "effect", "disabled", "trigger"]))
}